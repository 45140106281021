import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Logo from '../images/Logo'
import CCC from '../svg/spasa-climate-care-certified'
import Twitter from '../svg/twitter'
import Instagram from '../svg/instagram'
import Facebook from '../svg/facebook'
import Github from '../svg/github'

const FooterSection = tw.footer`bg-gray-800 py-0 md:py-8`
const Line = styled.hr`
	${tw`bg-gray-600 border-none`}
	height: 1px;
`
const ClimateCare = styled.div`
	${tw`flex flex-row items-center order-1 md:order-2 w-full md:w-auto mb-4 md:mb-0`}
	a > svg:hover path {
		fill: #d2d6dc;
	}
`
const Socials = styled.div`
	${tw`flex flex-row mt-6`}

	> a > svg:hover path {
		fill: #d2d6dc;
	}

	> a:not(:first-of-type) {
		${tw`ml-3`}
	}
`
const Footer = (): JSX.Element => {
	const { social } = useStaticQuery(graphql`
		{
			social: allContentfulNavMenu(filter: { contentful_id: { eq: "39Cox3N8UQi2xJYLYYPh0E" } }) {
				edges {
					node {
						ariaLabel
						navId
						menuItems {
							... on ContentfulNavItem {
								title
								url
								icon
							}
						}
					}
				}
			}
		}
	`)
	const socials = social.edges[0]

	// interface Icons<T> {
	// 	icons: T
	// 	children: (icons: T) => JSX.Element
	// }

	// const Icon = <T extends unknown[]>({ icons, children }: Icons<T>): JSX.Element => {
	// 	return children(icons)
	// }
	// const icons = [
	// 	{
	// 		title: 'Facebook',
	// 		icon: <Facebook />,
	// 	},
	// 	{
	// 		title: 'Instagram',
	// 		icon: <Instagram />,
	// 	},
	// 	{
	// 		title: 'Github',
	// 		icon: <Github />,
	// 	},
	// 	{
	// 		title: 'Twitter',
	// 		icon: <Twitter />,
	// 	},
	// ]

	// interface SocialObj<T> {
	// 	title: T
	// 	icon: T
	// 	url: T
	// 	__typeName: T
	// }
	interface SocialObj {
		title: string
		icon: string
		url: string
		__typeName: string
	}

	const checkIcon = (icon: string): JSX.Element => {
		const size = '24'
		const color = '#6b7280'
		switch (icon) {
			case 'twitter':
				return <Twitter width={size} height={size} color={color} />
			case 'github':
				return <Github width={size} height={size} color={color} />
			case 'instagram':
				return <Instagram width={size} height={size} color={color} />
			case 'facebook':
				return <Facebook width={size} height={size} color={color} />
			default:
				return <></>
		}
	}

	return (
		<FooterSection>
			<div tw="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<div tw="flex flex-col md:flex-row mb-8">
					<div tw="w-full md:w-4/12 mb-10 md:mb-0">
						<Link tw="inline-block" to="/">
							<span tw="sr-only">Maytronics Australia</span>
							<Logo tw="h-10 md:h-9 lg:h-11 pt-2 w-auto text-white" role="img" />
						</Link>
						<p tw="text-gray-500 mt-4">Global Leaders in Robotic Pool Cleaners!</p>

						<Socials>
							{socials?.node?.menuItems.map((x: SocialObj, i: number) => {
								return (
									<Link key={i} to={x.url} target="blank" rel="noreferrer">
										{checkIcon(x.icon)}
									</Link>
								)
							})}
						</Socials>
					</div>
					<div tw="w-full md:w-8/12 grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-4">
						<div tw="pt-2">
							<h4 tw="text-gray-400 uppercase font-extrabold mb-3 text-sm">Products</h4>
							<ul>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/dolphin">
										Dolphin
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/dolphin/commercial">
										Dolphin Commercial
									</Link>
								</li>
								{/* <li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Mineral Swim
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Ozone Swim
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Eco Swim
									</Link>
								</li> */}
							</ul>
						</div>
						<div tw="pt-2">
							<h4 tw="text-gray-400 uppercase font-extrabold mb-3 text-sm">About</h4>
							<ul>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/about-us#our-history"
										target="_blank"
										rel="noreferrer"
									>
										Our History
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/about-us#our-values"
										target="_blank"
										rel="noreferrer"
									>
										Our Values
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/about-us#our-mission"
										target="_blank"
										rel="noreferrer"
									>
										Our Mission
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/testimonials"
										target="_blank"
										rel="noreferrer"
									>
										Testimonials
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/news/"
										target="_blank"
										rel="noreferrer"
									>
										News
									</Link>
								</li>
							</ul>
						</div>
						<div tw="pt-2">
							<h4 tw="text-gray-400 uppercase font-extrabold mb-3 text-sm">Support</h4>
							<ul>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/contact-us">
										Contact
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/support/faq/"
										target="_blank"
										rel="noreferrer"
									>
										Knowledge Base
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/support/registration"
										target="_blank"
										rel="noreferrer"
									>
										Warranty Registration
									</Link>
								</li>
								{/* <li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Repairs &amp; Parts
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Find a Dealer
									</Link>
								</li> */}
								{/* <li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Become a Dealer
									</Link>
								</li> */}
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/support/dolphins-purchased-from-outside-australia/"
										target="_blank"
										rel="noreferrer"
									>
										Overseas Purchases
									</Link>
								</li>
							</ul>
						</div>
						<div tw="pt-2">
							<h4 tw="text-gray-400 uppercase font-extrabold mb-3 text-sm">Legal</h4>
							<ul>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/privacy"
										target="_blank"
										rel="noreferrer"
									>
										Privacy
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link
										tw="text-gray-500 hover:text-gray-400 font-medium"
										to="https://maytronics.com.au/terms"
										target="_blank"
										rel="noreferrer"
									>
										Terms
									</Link>
								</li>
								<li tw="mb-0.5">
									<Link tw="text-gray-500 hover:text-gray-400 font-medium" to="/">
										Sitemap
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Line />
				<div tw="flex flex-col md:flex-row mt-10 justify-between items-center">
					<p tw="text-gray-500 order-2 md:order-1">
						{' '}
						&copy; {new Date().getFullYear()} Maytronics Australia. All rights reserved.
					</p>

					<ClimateCare>
						<Link to={`https://maytronics.com.au/post/climate-care-certified`} target="_blank" rel="noreferrer">
							<CCC size="120" color="#6b7280" />
						</Link>
						<Link
							tw="ml-8 text-sm text-gray-500 hover:text-gray-400"
							to="https://sustainability.maytronics.com/"
							target="_blank"
							rel="noreferrer"
						>
							ISO 14001:2015
						</Link>
					</ClimateCare>
				</div>
			</div>
		</FooterSection>
	)
}

export default Footer
