import React from 'react'
import PropTypes from 'prop-types'

const Instagram = ({ color, width, height }) => {
	return (
		<svg width={width} height={height} x="0" y="0" viewBox="0 0 24 24" id="instagram">
			<circle fill={color} cx="12" cy="12" r="2.4" />
			<path
				fill={color}
				d="M17.6,7.8C17.4,7.5,17.3,7.2,17,7c-0.3-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-1.3-0.2c-0.8,0-1,0-2.9,0
	c-0.2,0-0.4,0-0.6,0c-1.3,0-1.6,0-2.2,0c-0.7,0-1.1,0.1-1.3,0.2C7.5,6.6,7.2,6.7,7,7C6.7,7.2,6.6,7.5,6.4,7.8
	C6.4,8.1,6.2,8.4,6.2,9.1c0,0.8,0,1,0,2.9c0,1.9,0,2.1,0,2.9c0,0.7,0.1,1.1,0.2,1.3C6.6,16.5,6.7,16.8,7,17c0.3,0.3,0.5,0.4,0.8,0.5
	c0.3,0.1,0.6,0.2,1.3,0.2c0.8,0,1,0,2.9,0s2.1,0,2.9,0c0.7,0,1.1-0.1,1.3-0.2c0.3-0.1,0.6-0.3,0.8-0.5c0.3-0.3,0.4-0.5,0.5-0.8
	c0.1-0.3,0.2-0.6,0.2-1.3c0-0.8,0-1,0-2.9s0-2.1,0-2.9C17.8,8.4,17.6,8.1,17.6,7.8z M12,15.7c-2,0-3.7-1.6-3.7-3.7
	c0-2,1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7C15.7,14,14,15.7,12,15.7z M15.8,9.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9
	c0.5,0,0.9,0.4,0.9,0.9S16.3,9.1,15.8,9.1z"
			/>
			<path
				fill={color}
				d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M19.1,14.9c0,0.8-0.2,1.3-0.3,1.7
	c-0.2,0.5-0.4,0.9-0.8,1.3c-0.4,0.4-0.8,0.6-1.3,0.8c-0.5,0.2-1,0.3-1.7,0.3c-0.8,0-1,0-2.9,0c-1.9,0-2.2,0-2.9,0
	c-0.8,0-1.3-0.2-1.7-0.3c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.5-0.3-1-0.3-1.7c0-0.8,0-1,0-2.9
	c0-1.9,0-2.2,0-2.9c0-0.8,0.2-1.3,0.3-1.7c0.2-0.5,0.4-0.9,0.8-1.3c0.4-0.4,0.8-0.6,1.3-0.8c0.5-0.2,1-0.3,1.7-0.3c0.8,0,1,0,2.9,0
	c1.9,0,2.2,0,2.9,0c0.8,0,1.3,0.2,1.7,0.3c0.5,0.2,0.9,0.4,1.3,0.8s0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.7c0,0.8,0,1,0,2.9
	C19.1,13.9,19.1,14.2,19.1,14.9z"
			/>
		</svg>
	)
}

Instagram.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
Instagram.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default Instagram
