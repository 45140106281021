import { useContext } from 'react'
import { UIContext, State, Action } from '../context/UIContext'

interface UseUI {
	UI: State
	dispatchUI: React.Dispatch<Action>
	toggleDark: () => void
	toggleMobile: () => void
	closeMobile: () => void
	toggleSearch: () => void
	closeSearch: () => void
	toggleSettings: () => void
}

const useAuth = (): UseUI => {
	const [UI, dispatchUI] = useContext(UIContext)
	const toggleDark = (): void => dispatchUI({ type: 'DARK' })
	const toggleMobile = (): void => dispatchUI({ type: 'MOBILE', isMobile: null })
	const closeMobile = (): void => dispatchUI({ type: 'MOBILE', isMobile: null })
	const toggleSearch = (): void => dispatchUI({ type: 'SEARCH', isSearch: null })
	const closeSearch = (): void => dispatchUI({ type: 'SEARCH', isSearch: false })
	const toggleSettings = (): void => dispatchUI({ type: 'SETTINGS', isSettings: null })

	return {
		UI,
		dispatchUI,
		toggleDark,
		toggleMobile,
		closeMobile,
		toggleSearch,
		closeSearch,
		toggleSettings,
	}
}

export default useAuth
