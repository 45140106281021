import React from 'react'
import PropTypes from 'prop-types'

const Facebook = ({ color, width, height }) => {
	return (
		<svg width={width} height={height} x="0" y="0" viewBox="0 0 24 24" fill="none" id="facebook">
			<path
				fill={color}
				d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M15.6,6.9h-1.9c-1,0-1.2,0.7-1.2,1.3v1.3
	h2.9L15,12h-2.6v7.7H9.9V12H8.4V9.4H10V7.9c0-2.1,0.7-3.6,2.9-3.6h2.7C15.6,4.3,15.6,6.9,15.6,6.9z"
			/>
		</svg>
	)
}

Facebook.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
Facebook.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default Facebook
