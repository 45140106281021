import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

interface Image {
	url?: string
	width?: number | string
	height?: number | string
}
interface Meta {
	name: string
	content: string
}

interface Props {
	title: string
	lang?: string
	meta?: Meta[]
	image?: Image
	type?: string
	keywords?: string[]
	description?: string
	slug?: string
	location?: Location
	children?: React.ReactNode
}

interface Metas {
	metaDescription: string
	metaKeywords: string[]
	metaLang: string
	metaCountry: string
	metaPath: string
	metaURL: string
}

export const SEO = ({
	title,
	meta = [],
	lang,
	keywords,
	description,
	image,
	type,
	slug,
	...props
}: Props): JSX.Element => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						title
						description
						keywords
						author
						locale
						country
					}
				}
			}
		`
	)

	const [metas, setMetas] = useState<Metas>(Object)
	useEffect(() => {
		const metaDescription = description || site.siteMetadata.description
		const metaKeywords = keywords || site.siteMetadata.keywords
		const metaLang = lang || site.siteMetadata.locale
		const metaCountry = site.siteMetadata.country
		const metaPath = slug || location.pathname
		const metaURL = site.siteMetadata.siteUrl

		setMetas({
			metaDescription: metaDescription,
			metaKeywords: metaKeywords,
			metaLang: metaLang,
			metaCountry: metaCountry,
			metaPath: metaPath,
			metaURL: metaURL,
		})
	}, [])

	return (
		<Helmet
			htmlAttributes={{ lang: metas.metaLang }}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `robots`,
					content: `index, follow`,
				},
				{
					name: `googlebot`,
					content: `index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
				},
				{
					name: `bingbot`,
					content: `index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
				},
				{
					name: `description`,
					content: metas.metaDescription,
				},
				{
					property: `og:locale`,
					content: metas.metaLang,
				},
				{
					property: `og:type`,
					content: type,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metas.metaDescription,
				},
				{
					property: `og:site_name`,
					content: site.siteMetadata.title,
				},
				// meta.type === `artical` && {
				// 	property: `article:publisher`,
				// 	content: `https://facebook.com/MaytronicsAustralia`,
				// },
				// meta.type === `artical` && {
				// 	property: `article:modified_time`,
				// 	content: meta.createdAt,
				// },
				{
					property: `og:image`,
					content: image ? image.url : '',
				},
				{
					property: `og:image:width`,
					content: image ? image.width : '0',
				},
				{
					property: `og:image:height`,
					content: image ? image.height : '0',
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metas.metaDescription,
				},
			]
				.concat(
					metas.metaKeywords?.length > 0
						? {
								name: `keywords`,
								content: metas.metaKeywords.join(`, `),
						  }
						: []
				)
				.concat(meta)}
		>
			{props.children}
			{slug && <link rel="canonical" href={metas.metaURL + metas.metaPath + '/'} />}
			<script type="application/ld+json" className="schema-graph">
				{JSON.stringify(
					{
						'@context': 'https://schema.org',
						'@graph': [
							{
								'@type': 'Organization',
								'@id': metas.metaURL + '#organization',
								name: `Maytronics ${metas.metaCountry}`,
								url: metas.metaURL,
								sameAs: [
									'https://maytronics.com.au',
									'https://facebook.com/MaytronicsAustralia',
									'https://linkedin.com/company/1152712?trk=tyah&amp;trkInfo=tarId:1412557516527,tas:maytronics,idx:1-1-1',
									'https://youtube.com/channel/UCkViJViFLKFyAnRWcNocvnA',
									'https://twitter.com/mydolphinaus',
								],
								logo: {
									'@type': 'ImageObject',
									'@id': metas.metaURL + '#logo',
									inLanguage: metas.metaLang,
									url:
										'https://images.ctfassets.net/ftcnv5up9dxv/6ZXomSvReH7JrmvWGCNx5Z/d9ca707056aa21290732b8b316a2cbb4/maytronics.svg',
									width: 209,
									height: 50,
									caption: `Maytronics ${metas.metaCountry}`,
								},
								image: { '@id': metas.metaURL + '#logo' },
							},
							{
								'@type': 'WebSite',
								'@id': metas.metaURL + '#website',
								url: metas.metaURL,
								name: site.siteMetadata.title,
								description: metas.metaDescription,
								publisher: {
									'@id': metas.metaURL + '#organization',
								},
								potentialAction: [
									{
										'@type': 'SearchAction',
										target: metas.metaURL + '?s={search_term_string}',
										'query-input': 'required name=search_term_string',
									},
								],
								inLanguage: metas.metaLang,
							},
							{
								'@type': 'WebPage',
								'@id': metas.metaURL + metas.metaPath + '/#webpage',
								url: metas.metaURL + metas.metaPath + '/',
								name: title,
								isPartOf: { '@id': metas.metaURL + '/#website' },
								primaryImageOfPage: {
									'@id': metas.metaURL + metas.metaPath + '/#primaryimage',
								},
								inLanguage: metas.metaLang,
								potentialAction: [
									{
										'@type': 'ReadAction',
										target: [metas.metaURL + metas.metaPath + '/'],
									},
								],
							},
						],
					},
					null,
					0
				)}
			</script>
		</Helmet>
	)
}

export default SEO
