import React from 'react'
import tw, { css } from 'twin.macro'
import useUI from '../hooks/UIHooks'

const ToggleDark: React.FC = () => {
	const { UI, toggleDark } = useUI()
	return (
		<button
			type="button"
			tw="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
			aria-pressed={UI.isDark === 'dark'}
			onClick={toggleDark}
			css={
				UI.isDark === 'dark' ? tw`bg-gray-600 focus:ring-offset-gray-900` : tw`bg-gray-300 focus:ring-offset-gray-100`
			}
			title={'Turn dark mode ' + (UI.isDark === 'dark' ? 'off' : 'on')}
		>
			<span tw="sr-only">Use dark mode</span>
			<span
				css={UI.isDark === 'dark' ? tw`translate-x-5! bg-gray-900` : tw`translate-x-0! bg-white`}
				tw="pointer-events-none relative inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200 translate-x-5"
			>
				<span
					tw="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-0 ease-out duration-100"
					aria-hidden="true"
					css={UI.isDark === 'dark' ? tw`opacity-0! ease-out! duration-100!` : tw`opacity-100! ease-in duration-200!`}
				>
					{/** Heroicon name: solid/sun */}
					<svg tw="h-4 w-4 text-gray-900" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
						<path
							fillRule="evenodd"
							d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
							clipRule="evenodd"
						/>
					</svg>
				</span>
				<span
					tw="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity opacity-100 ease-in duration-200"
					aria-hidden="true"
					css={UI.isDark === 'dark' ? tw`opacity-100! ease-in! duration-200!` : tw`opacity-0! ease-out! duration-100!`}
				>
					{/** Heroicon name: solid/moon */}
					<svg tw="h-4 w-4 text-gray-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
						<path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
					</svg>
				</span>
			</span>
		</button>
	)
}

export default ToggleDark
