import React from 'react'
import PropTypes from 'prop-types'

const Twitter = ({ color, width, height }) => {
	return (
		<svg width={width} height={height} x="0" y="0" viewBox="0 0 24 24" fill="none" id="twitter">
			<path
				d="M12.0005 0C5.37281 0 0 5.37378 0 12.0005C0 18.6282 5.37281 24 12.0005 24C18.6282 24 24 18.6282 24 12.0005C24 5.37378 18.6277 0 12.0005 0ZM18.0453 9.5809C18.0517 9.71053 18.0541 9.84064 18.0541 9.97222C18.0541 13.9688 15.0122 18.5765 9.44981 18.5765C7.7422 18.5765 6.15205 18.076 4.81384 17.2183C5.05068 17.2461 5.29094 17.2602 5.53509 17.2602C6.95224 17.2602 8.25634 16.7768 9.29094 15.9659C7.96784 15.941 6.85137 15.0663 6.46637 13.865C6.65059 13.9011 6.84016 13.9191 7.03509 13.9191C7.31092 13.9191 7.57797 13.883 7.83187 13.8134C6.44786 13.5356 5.40546 12.3124 5.40546 10.8484C5.40546 10.8353 5.40546 10.8226 5.40595 10.8095C5.81335 11.0361 6.27973 11.172 6.77583 11.1881C5.96394 10.6447 5.43031 9.72027 5.43031 8.67057C5.43031 8.11647 5.57943 7.59649 5.83967 7.1501C7.33138 8.97953 9.55994 10.1837 12.0736 10.3095C12.0219 10.0887 11.9951 9.85721 11.9951 9.62037C11.9951 7.95078 13.3489 6.59649 15.0195 6.59649C15.8894 6.59649 16.6749 6.96394 17.2266 7.55117C17.9157 7.41618 18.5629 7.16374 19.1472 6.81725C18.9206 7.52388 18.4415 8.11647 17.8168 8.49025C18.4293 8.41715 19.0122 8.25439 19.5541 8.01413C19.1496 8.62183 18.6365 9.15448 18.0453 9.5809Z"
				fill={color}
			/>
		</svg>
	)
}

Twitter.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
Twitter.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default Twitter
