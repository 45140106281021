import React from 'react'
import tw, { GlobalStyles } from 'twin.macro'
import useUI from '../hooks/UIHooks'
import Header from './Header'
import Footer from './Footer'
import '../fonts/Inter/inter.css'
import '../fonts/Flexo/flexo.css'
import '../fonts/Magistral/magistral.css'

const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
	const { UI } = useUI()
	return (
		<div className={UI.isDark}>
			<GlobalStyles />
			<Header />
			<main id="main" tw="z-10">
				{children}
			</main>
			<Footer />
			{/* <footer tw="mt-8">
				© {new Date().getFullYear()}, Built with
				{` `}
				<a href="https://www.gatsbyjs.com">Gatsby</a>
			</footer> */}
		</div>
	)
}

export default Layout
