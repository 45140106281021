import React from 'react'
const Logo = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 50 50"
		fill="currentColor"
		aria-label="Maytronics brand"
		{...props}
	>
		<title>Maytronics brand</title>
		<path
			d="M25.6,13.7L25.6,13.7c-5.8,0-10.6,5.1-10.6,11.5c0.6,0.2,1.1,0.4,1.6,0.7c0.6,0.3,1.2,0.6,1.8,1
	c-0.1-0.5-0.1-1-0.1-1.6c0-4.4,3.3-8,7.3-8c4,0,7.3,3.6,7.3,8c0,0.7-0.1,1.4-0.2,2c1.4-0.3,2.6-1.1,3.5-2.2
	C36.2,18.9,31.5,13.7,25.6,13.7"
		/>
		<path
			fill="#58C8DF"
			d="M35.4,27.8c-2.6,2.4-6.1,3.6-9.6,3.3c-2.1-0.2-4.1-0.8-6-1.8l-1.3-0.7c-0.3-0.2-0.5-0.4-0.8-0.5
	c-0.6-0.3-1.1-0.6-1.7-0.9c-2.3-1.1-4.9-1.5-7.4-1.2c-3.1,0.4-6.1,1.6-8.6,3.3l0,0c2.6-1.1,5.4-1.5,8.2-1.3c2.3,0.2,4.5,1.1,6.3,2.4
	c0.5,0.3,0.9,0.7,1.4,1.1c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.2,0.7,0.6,1,0.9c1.4,1.2,3.1,2.1,5,2.7c3,0.9,6.3,0.5,9-1.1
	c1.6-1,3-2.3,4-4c0.8-1.2,1.2-2.5,1.1-3.9l0,0c-0.3,0.4-0.7,0.7-1,1.1"
		/>
	</svg>
)

export default Logo
